body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@media(min-width:768px){
  .circlesec {
    position: relative;
    min-width: 668px;
    min-height: 617px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.dropdow_heade_tar .dropdown-toggle 
{
margin-bottom: 0px !important;
}
.dropdow_heade_tar 
{
  margin-left: 10px;
}

.dropdow_heade_tar  .dropdown-menu .dropdown-item
{
  background: #5ec0df14 !important;
}
.dropdow_heade_tar  .dropdown-menu
{
  left: unset !important;
    margin-top: 0.125rem;
    right: 0px;
    top: 40px !important;
    background: #60c1dd !important;
}

@media only screen and (max-width:991px)
{
  .dropdwn_trna.dropdow_heade_tar
  {
    text-align: center;
    margin-top: 15px !important;
  }
}
.btn_yellow.wallet_btn:focus {
  background: transparent !important;
  color:#fff;
  border-color: #A65EDF;
}

.newplan{
  color: #a65edf !important;
}