@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: "sunny";
  src: url("../src/fonts/Sunny\ Sunday.ttf");
}

:root {
  --themeclr: #629b00
}

body {
  font-family: 'sunny', sans-serif;
  background-color: #021523;
  min-height: 100vh;
}

.homepage_bg {
  background: #021523;
}

.land {
  background-image: url("../src/images/bg.jpg");
  background-size: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh;

}

header img {
  height: 110px;
  margin-top: 6px;
}

.owl-nav button span {
  background: #629b00;
  width: 30px;
  display: inline-block;
  height: 30px;
  line-height: 1.8;
  border-radius: 50% !important;
  color: white;
}

.loader img.spin {

  max-width: 150px;
  animation-name: spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.leaf {
  position: absolute;
  height: 50px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.showloader {
  height: 100vh;
  position: fixed;
  background: #282727cc;
  width: 100%;
  z-index: 1031;
  margin: auto;
  display: none;
  top: 0px;
  left: 0px;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.yellowbtn[disabled] {
  cursor: not-allowed !important;
}

html {
  background-color: #000;
}

.themeclr {
  color: var(--themeclr);
}

.upper {
  text-transform: uppercase;
}

.stepbg {
  width: 100%;
}

.owl-nav button span {
  background: #fff;
  width: 40px;
  display: inline-block;
  height: 40px;
  line-height: 1.8;
  border-radius: 50% !important;
  font-size: 30px !important;
  color: #000000;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.owl-nav button:hover span,
.owl-nav button:hover {
  border-radius: 50% !important;
  background: #d4d4d463 !important;
}

.steps3 .step1btn,
.steps5 .step1btn {
  padding: 13px;
}

.steps3 .step1btn h3 {
  color: #706e6f !important;
}

.step1btn {
  border-radius: 50%;
  height: 120px;
  width: 120px;
  box-shadow: 0 0 16px gray;
  background-image: radial-gradient(#e7e7e7 20%, #c6c5c5 66%, var(--themeclr) 15%);
  outline: none;
  border: 1px solid var(--themeclr);
}

.step1btn p {
  margin-bottom: 0;
}

.step1btn h3 {
  color: #706e6f;
}

.steps {
  position: absolute;
  right: 88px;
  bottom: 0;
}

.steps1 {
  position: absolute;
  right: 12%;
  top: 26%;
}

.steps2 {
  position: absolute;
  left: 8%;
  bottom: 10%;
}

.steps3 {
  position: absolute;
  bottom: 24%;
  right: 10%;
}

.steps4 {
  position: absolute;
  left: 10%;
  bottom: 12%;
}

.steps5 {
  position: absolute;
  top: 25%;
  left: 15%;
}

.steps5 .step1btn {
  background-image: linear-gradient(#476e04, #6baa01);
  border-color: white;
  border-width: 5px;
  height: 130px;
  width: 130px;
  word-break: break-all;
}

.steps1 .step1btn,
.steps2 .step1btn,
.steps3 .step1btn,
.steps4 .step1btn {
  height: 140px;
  width: 140px;
  word-break: break-all;
}

.step1btn h3 {
  font-size: 14px;
}

.laststep .steps1 .step1btn {
  display: block;
  width: 40px !important;
  height: 40px !important;
  aspect-ratio: 1/1;
  font-size: 12px;
  line-height: 3;
}

.laststep .steps7 {
  position: absolute;
  right: 14%;
  top: 26%;
}

.step7btn {
  border-radius: 50%;
  height: 126px;
  width: 126px;
  box-shadow: 0 0 16px gray;
  background-image: radial-gradient(#e7e7e7 20%, #c6c5c5 66%, var(--themeclr) 15%);
  outline: none;
  border: 1px solid var(--themeclr);
}

.step7btn h3 {
  font-size: 14px;
  color: #706e6f !important
}

.laststep .steps1 {
  position: absolute;
  right: 43%;
  top: 19%;
}

.laststep .steps1 .step1btn {
  height: 30px;
  width: 30px;
  background-image: linear-gradient(#b60d84, #701280);
  border-color: white;
  color: white;
  border-width: 2px;
}

.circlesec {
  position: relative;
}

.centertext {
  position: absolute;
}

.centertext {
  position: absolute;
  left: 0;
  right: 0;
  top: 14%;
  bottom: 0;
  height: min-content;
  margin: auto;
}

.centerinput .harvestbtn {
  width: 64px !important;
  background: #d4d6d5;
  color: white;
  border: none;
  color: gray;
  padding-left: 5px;
  margin-right: 5px !important;
  border-radius: 5px;
  height: 33px;
}

.centerinput button {
  width: 30px;
  border-radius: 5px;
  color: white;
  color: white;
  border: none;
}

.centerinput button.plus {
  background: #393939;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.centerinput button.minus {
  background: #8c8a8b;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bord-badge {
  border: 1px solid var(--themeclr);
  width: 100px;
  margin: auto;
  margin-bottom: 22px;
  border-radius: 5px;
}

.nav-item a {
  display: inline-block;
}

.nav-link {
  position: relative;
  overflow: hidden;
}




.nav-item.completed::after {
  background: #69ab01 !important;
}

.nav-link {
  cursor: pointer;
}

.nav-link:hover,
.nav-link:focus {
  color: #000 !important;
}

.cnctbtn.btn {
  background: #629b00;
  color: white;
  position: relative;
  top: -16px;
}

.cnctbtn img {
  width: 14px;
  filter: invert(1);
}

.modal-content {
  /* height: 529px; */
  background: #000b16;
  border: none;
  border-radius: 25px;
}

.modal-header {
  border: none;
}

.btn-close {
  opacity: 1 !important;
  /* filter: invert(1); */
}

.btn.cancel {
  background: #080808;
  color: white;
  box-shadow: 0 0 6px 4px #c8c8c8;
  margin-right: 10px;
}

.btn.confirm {
  background: #a7d30a;
  box-shadow: 0 0 6px 4px #c8c8c8
}

.modalcont input,
.modalcont .input-group-text {
  border-radius: 0 10px 10px 0;
  background: #d8d6d7;
}

.modalcont .input-group-text {
  padding: 5px;
}

.maxbtn.btn {
  border-radius: 10px;
}

.modalcont .input-group button {
  background: #9f0d4e;
  color: white;
}

.modalcont input {
  border-radius: 10px 0 0 10px;
}

.circlemodal {
  position: relative;
}

.modalcont {
  position: absolute;
  top: 13%;
  z-index: 9;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0;
  width: 300px;
  height: 197px;
}

.tab-cont .centertext img {
  width: auto !important;
  margin: auto !important;
}

@media(max-width:767px) {
  .border_right_md_wallet {
    border-bottom: 1px solid #2b2828;
  }

  .steps1 .step1btn,
  .steps2 .step1btn,
  .steps3 .step1btn,
  .steps4 .step1btn {
    height: 120px;
    width: 120px;
  }

  .step1btn p {
    font-size: 12px;
  }

  .tab-cont .centertext .leafimg {
    width: 150px !important;
  }

  .steps .step1btn,
  .steps1 .step1btn,
  .steps2 .step1btn,
  .steps3 .step1btn,
  .steps4 .step1btn {
    /* height: 100px;
    width: 100px; */
  }

  .steps5 .step1btn {
    height: 80px;
    width: 80px;
  }
}

@media(min-width:768px) {
  .border_right_md_wallet {
    border-right: 1px solid #2b2828;
  }

  .minwidth-534 {
    min-width: 668px;
  }

  .laststep .minwidth-534 {
    width: 668px;
    height: auto;
  }
}

@media(min-width:992px) {
  .modalcont {
    left: -11%;
  }

  .flexcolumn {
    flex-direction: column;
  }
}

@media(max-width:991px) {

  .minwidth-534 {
    margin: auto;
  }

  .nav-item:last-child {
    width: auto;
  }

  .nav-item::after {
    width: 100%;
    height: 3px;
    top: 20px;
    right: 0;
  }

  .nav-pills {
    justify-content: center;
  }

}

@media(max-width:600px) {
  .modalbg {
    margin: auto;
    width: 100%;
  }

  .modalcont {
    left: -12%;
  }
}

@media(max-width:575px) {
  .flex_drop_div {
    display: block !important;
  }

  .input_grp_nowrap {
    flex-wrap: nowrap !important;
  }


  .input_grp_nowrap .dropdown-toggle {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .input_grp_nowrap input {
    /* padding-left: 5px !important; */
    padding-right: 5px !important;
  }

  .input_grp_nowrap .input-group-text {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .input_grp_nowrap .dropdown-toggle {
    font-size: 11px !important;
  }

  .laststep .steps7 {
    right: 6%;
    top: 26%;
  }

  .modalcont p.token {
    font-size: 10px;
    font-weight: 400;
  }

  h4.dephead {
    font-size: 14px;
  }

  h6.dephead {
    font-size: 12px;
  }

  .step1btn {
    box-shadow: none !important;
  }

  header img {
    height: 80px;
    margin-top: 8px;
  }

  .modalcont input {
    font-size: 12px;
  }

  .modalcont h3 {
    font-size: 18px;
  }

  .modalcont {
    width: 250px;
  }

  .cnctbtn.btn {
    padding: 2px 9px;
    font-size: 12px;
  }

  .head h2 {
    font-size: 18px !important;
    margin-bottom: 0;
  }

  .steps .step1btn,
  .steps1 .step1btn,
  .steps2 .step1btn,
  .steps3 .step1btn,
  .steps4 .step1btn,
  .steps5 .step1btn {
    height: 120px;
    width: 120px;
  }

  .tab-cont .centertext .leafimg {
    width: 130px !important;
  }

  .step1btn p {
    font-size: 9px;
  }

  .centerinput .harvestbtn,
  .centerinput button {
    height: 23px;
  }

  .step1btn h3 {
    font-size: 12px;
    margin-bottom: 0;
  }

  .laststep .steps1 {
    right: 44%;
    top: 18%;
  }

  .steps5 {
    top: 17%;
    left: 15%;
  }

  .steps4 {
    left: 7%;
    bottom: 1%;
  }

  .steps3 {
    bottom: 12%;
    right: 3%;
  }

  .bord-badge {
    width: 60px;
    font-size: 12px;
    margin-bottom: 5px;
  }

  .centertext p {
    margin-bottom: 0;
  }
}

@media(max-width:475px) {

  input {
    height: 34px;
    font-size: 12px;
  }

  .modalcont .input-group button {
    height: 32px;
    line-height: 1;
    font-size: 12px;
  }

  .modalcont .input-group-text {
    padding: 0;
  }

  .btn.cancel,
  .btn.confirm {
    height: 30px;
    line-height: 1;
    font-size: 12px;
  }

  .centertext p {
    font-size: 12px;
  }

  .wall,
  .meta {
    width: 30px;
  }

  .arrow {
    display: none;
  }

  .steps {
    right: 43px;
    bottom: 0;
  }

  .steps2 .step1btn {
    height: 88px;
    width: 88px;
  }

  .steps .step1btn,
  .steps1 .step1btn,
  .steps4 .step1btn {
    height: 80px;
    width: 80px;
  }

  .tab-cont .centertext .leafimg {
    width: 44px !important;
  }
}

@media(max-width:425px) {
  .harvestbtn {
    font-size: 12px;
  }

  .steps5 {
    top: 15%;
    left: 6%;
  }

  .steps5 .step1btn,
  .steps3 .step1btn {
    height: 100px;
    width: 100px;
  }

  .modalcont {
    width: 160px;
  }

  .hand {
    width: 25px;
    margin-top: 18px;
  }

  .modalcont h3 {
    font-size: 15px;
  }
}

@media(max-width:400px) {
  .step7btn {
    height: 115px;
    width: 115px;
  }

  .laststep .steps7 {
    right: 0%;
    top: 18%;
  }

  .steps3 {
    bottom: 0%;
    right: 0%;
  }

  .steps3 .step1btn {
    height: 90px;
    width: 90px;
  }
}

@media(min-width:375px) and (max-width:575px) {
  .steps4 {
    left: 20px;
    bottom: 11%;
  }
}

@media(max-width:375px) {
  .steps4 {
    left: 0px;
    bottom: 16%;
  }
}

.cnctbtn.btn img {
  width: 14px !important;
  display: inline-block !important;
}

.cnctbtn.btn:hover,
.btn.confirm:hover {
  background: #080808;
  color: white;
}

.btn.cancel:hover {
  background: #a7d30a;
  box-shadow: 0 0 6px 4px #c8c8c8;
  color: #212529;

}

.modalcont .input-group button:hover {
  background: #a7d30a;
  color: #212529;
}

.step1btn:hover {
  /* background-image: linear-gradient(55deg ,#69ab01 39% , #548603 84%) !important; */
  background-image: radial-gradient(#e7e7e7 20%, #c6c5c5 63%, #e5e6e5 15%);
  outline: none;
  /* border: 5px solid #d5d4d4; */
}

.steps3.steps_new1 {
  position: absolute;
  left: 14% !important;
  right: unset !important;
  bottom: unset !important;

  top: 18%;
}

.steps4.steps_new1 {
  position: absolute;
  right: 12% !important;
  left: unset !important;
  bottom: 0% !important;

}

.step1btn:hover .text-light {
  color: #706e6f !important;
}

/* .modal-content
{
  align-items: center;
    justify-content: center;
    height: 100vh;
} */
.modal-header {
  position: absolute;
  top: 5%;
  right: 0%;
  z-index: 1;
}

/* .modal-body

{
  display: flex;
    align-items: center;
    justify-content: center;
} */

@media only screen and (min-width:601px) and (max-width:991px) {
  .modalcont {
    left: -12% !important;
  }
}

.btn,
.btn-close {
  box-shadow: 0 0 0 0rem rgb(13 110 253 / 25%) !important;
}

.close_btn {
  font-size: 39px;
  font-weight: 200;
  color: white;
}


/* USDI CSS */
.link_to_stake {
  color: #629b01;
  margin-top: 25px;
}

.link_to_stake:hover {
  color: #000;
}

.link_to_usdi {
  color: #4da475;
  margin-top: 25px;
}

.link_to_usdi:hover {
  color: #000;
}

.usdi_landing {
  background-image: url("../src/images/bg_usdi.png");
  background-size: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh;
}

.usdi_landing .nav-link.active,
.usdi_landing .nav-item.completed .nav-link,
.usdi_landing .nav-link:hover {
  border-radius: 50% !important;
  background-color: #4da475;
  background-image: linear-gradient(55deg, #64d696 39%, #4da475 84%) !important;
}

.usdi_landing .steps5 .step1btn {
  background-image: linear-gradient(#64d696, #4da475);
}

.usdi_landing .step7btn,
.usdi_landing .step1btn {
  background-image: radial-gradient(#e7e7e7 20%, #c6c5c5 66%, #4da475 15%);
  border: 1px solid #4da475;
}

.usdi_landing .themeclr {
  color: #4da475;
}

.usdi_landing .bord-badge {
  border: 1px solid #4da475;
}

.usdi_landing .cnctbtn.btn {
  background: #64d696;
}

.usdi_landing .nav-item.completed::after {
  background: #64d696 !important;
}

.usdi_landing .step1btn:hover .text-light {
  color: #fff !important;
}

.modal_usdi .btn.confirm,
.modal_usdi .btn.cancel:hover {
  background: #64d696;
}

.center_usdi {
  max-width: 165px;
}

.modal_usdi .btn.confirm:hover {
  background: #080808;
}

.modal_usdi .modalcont {
  top: 9%;
}

@media only screen and (min-width:992px) {
  .modal_usdi .modalcont {
    left: 0% !important;
  }
}

@media only screen and (max-width:600px) {
  .modal_usdi .modalbg {
    min-width: 300px !important;
  }

  .modal_usdi .modalcont {
    left: -3% !important;
  }
}

@media only screen and (min-width:426px) and (max-width:575px) {
  .modal_usdi .modalcont {
    top: 20% !important;
  }
}

@media only screen and (min-width: 601px) and (max-width: 991px) {
  .modal_usdi .modalcont {
    left: 0% !important;
  }
}

.modal_usdi .modalcont .input-group button:hover {
  background: #64d696;
}

.usdi_landing .centertext {
  top: 10%;
}

.usdi_landing .owl-nav button span {
  background: #64d696;
}

@media (max-width: 475px) {
  .usdi_landing .tab-cont .centertext .leafimg {
    width: 50px !important;
    margin-top: 10px !important;
  }
}

/* end USDI CSS */



/* NEW CSS */

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.btn_red {
  background-color: #FF6570;
  color: #000000 !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  border-radius: 20px 0px 20px 0px;
  padding: 8px 20px !important;
  -webkit-box-shadow: 0 0 16px 5px rgb(0 0 0 / 20%);
  -moz-box-shadow: 0 0 16px 5px rgb(0 0 0 / 20%);
  box-shadow: 0 0 16px 5px rgb(0 0 0 / 20%);
  border: 1px solid #FF6570;
}

.btn_red:hover {
  background-color: transparent !important;
  color: #FF6570 !important;
}

.big_image_wallet {
  max-width: 150px;
  margin-bottom: 20px;
}

.wallet_connect_div_btn {
  border-radius: 25px;
  max-width: max-content;
  padding: 5px 15px;
  cursor: pointer;
}

.wallet_connect_div_btn:hover {
  background-color: #A6598C;
}

.wallet_connect_div_btn:hover span {
  color: #000000 !important;
}

.wallet_connect_div_btn:hover .img_sm_wallet_div {
  background-color: #fff9;

}

.img_sm_wallet_div img {
  max-width: 20px;
}

.img_sm_wallet_div {
  background-color: #171717;
  border-radius: 10px;
  padding: 2px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .spin_animation
{
  animation: rotation 2s infinite linear;
  transform-origin: 0;
} */
@media (min-width: 576px) {
  .thememodal .modal-dialog {
    max-width: 600px !important;
  }
}

.wallet_text_center_white {
  color: #fff;
  font-size: 14px;
  text-align: center;
}

.wallet_text_heading {
  color: #fff;
  font-size: 13px;
}

.wallet_subtext_heading {
  color: #fff;
  font-size: 12px;
  font-weight: 300 !important;
}

.text_desc_a_span {
  color: #ccc;
  font-size: 11px !important;
  font-weight: 300 !important;
  padding-right: 3px;
}

.close-btn {
  border: 1px solid #A6598C;
  width: 20px;
  height: 20px;
  color: #A6598C;
  background-color: transparent !important;
  border-radius: 50%;
  font-size: 10px !important;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-top: 2px;

}

.a_with_span .headright_a {
  font-size: 11px !important;
}

.modal_title_white {
  color: #fff;
  font-size: 16px;
}

.wallet_connect_div span {
  color: #FFFFFF;
  font-size: 13px !important;
  margin-left: 10px;
}

.modal_subtitle_grey {
  color: #d7d6d6;
  font-size: 12px !important;
}

.step_count {
  display: none;
}

.step_count.active {
  display: block;
}

.container_custom {
  max-width: 93% !important;
}

.logoheader {
  max-width: 130px;
}

#navbarSupportedContent {
  justify-content: flex-end;
}

#header .nav-link {
  color: #fff;
  font-size: 14px;
  margin-right: 20px !important;
  border-bottom: 1px solid transparent;
}

#header .nav-link:hover,
#header .nav-link:focus,
#header .nav-link.active {
  color: #5EC0DF !important;
  /* border-bottom: 1px solid #A6598C; */

}

.btn_yellow {
  background-color: #59A661;
  color: #fff !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  border-radius: 30px;
  padding: 8px 20px !important;
  -webkit-box-shadow: 0 0 16px 5px rgb(0 0 0 / 20%);
  -moz-box-shadow: 0 0 16px 5px rgb(0 0 0 / 20%);
  box-shadow: 0 0 16px 5px rgb(0 0 0 / 20%);
  border: 1px solid transparent;
  height: 50px;
}

.btn_yellow:hover {
  background-color: transparent !important;
  color: #59A661 !important;
  border: 1px solid #59A661;
}

#header .btn_yellow {
  margin-right: 0px !important;
}

.landing_bg {

  background-image: url("../src/images/banner_bg.png");
  background-size: 100% 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  min-height: 100vh;
  position: relative;
}

.text_yellow {
  color: #A6598C;
}

.banner_txt_yel {
  font-size: 35px !important;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 20px !important;
}

.banner_txt_desc {
  font-size: 13px;
  color: #fff;
  font-weight: 300 !important;
  line-height: 2;
}

.coin_row img {
  max-width: 50px !important;
}

.card_shadow {
  background: rgba(32, 182, 115, 0.2);
  box-shadow: 2px 2px 13px #23B071;
  border-radius: 20px;
}

.text_sliver {
  color: #ECECEC;
  font-size: 12px;
}

.progress_text_div p {
  color: #ECECEC;
  font-size: 14px;
}

.single-thumb .range-slider__thumb[data-lower] {
  width: 0 !important;
  background: transparent !important;
  border: none !important;
  left: 0px !important;
}

.single-thumb .range-slider__range {
  border-radius: 6px;
}

.range-slider {
  background-color: #021523 !important;
  height: 4px !important;
}

.range-slider .range-slider__thumb {
  width: 15px !important;
  height: 15px !important;
  background: #A6598C !important;
  border: 2px solid #1c8dcc !important;
}

.dropdwn_trna button {
  background-color: transparent !important;
  border: none !important;
  color: #ECECEC;
  font-size: 12px;
  margin-bottom: 20px;
  padding: 0px !important;
}

.dropdown-menu {
  background-color: #021523 !important;
  color: #ECECEC;
  font-size: 12px;
}

.dropdown-menu .dropdown-item {
  color: #ECECEC;
  font-size: 12px;
}

.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus {
  background-color: #062033 !important;
}

.text_silver_desc {
  color: #ECECEC;
  font-size: 12px;
  font-weight: 300 !important;
}

.card_carusel {
  background-color: #3398E0;

  border-radius: 30px;
}

.owl-item.active.center .card_carusel {
  /* box-shadow: 0px 0px 13px #E8D257A6; */
}

.card_heading_white {
  color: #fff;
  text-transform: uppercase;
  font-weight: 300 !important;
  text-align: center;
  margin-bottom: 0px !important;
}

.text_price {
  color: #A6598C;
  text-align: center;
  margin-bottom: 0px !important;
}

.text_price_blue {
  color: #21A8F4;
  text-align: center;
  margin-bottom: 0px !important;
}

.text_price_blue span {
  font-size: 30px;
}

.text_price span {
  font-size: 30px;
}

.days_grey_price {
  color: #fff;
  font-size: 12px;
  text-align: center;
  font-weight: 300 !important;

}

.card_carusel .text_price {
  color: #fff !important;
}

.card_ul_car {
  list-style-type: none !important;
}

.card_ul_car li {

  font-size: 12px;
  padding: 15px 10px 15px 35px;
}

.blue_card .card_ul_car li:nth-child(odd) {
  background-color: #3E86B8;
  color: #ffffff !important;
  font-weight: 400;
}

.blue_card .card_ul_car li:nth-child(even) {
  background-color: #3398e0;
  color: #ffffff !important;
  font-weight: 400;
}


.pink_card .card_ul_car li:nth-child(odd) {
  background-color: #766599;
  color: #ffffff !important;
  font-weight: 400;
}

.pink_card .card_ul_car li:nth-child(even) {
  background-color: #8a73b9;
  color: #ffffff !important;
  font-weight: 400;
}

.green_card .card_ul_car li:nth-child(odd) {
  background-color: #488971;
  color: #ffffff !important;
  font-weight: 400;
}

.green_card .card_ul_car li:nth-child(even) {
  background-color: #4bb891;
  color: #ffffff !important;
  font-weight: 400;
}

.purple_card .card_ul_car li:nth-child(odd) {
  background-color: #af87cf;
  color: #ffffff !important;
  font-weight: 400;
}

.purple_card .card_ul_car li:nth-child(even) {
  background-color: #a65edf;
  color: #ffffff !important;
  font-weight: 400;
}

.check_yellow {
  color: #23b071;

}

.MuiSlider-rail {
  background-color: #92c807 !important;
  height: 4px !important;
}

.MuiSlider-root {
  /* width: 15px !important;
    height: 15px !important;   */
  color: #92c807 !important;
  /* border: 2px solid #1c8dcc !important;  */
}

.MuiSlider-thumb {
  width: 15px !important;
  height: 15px !important;
  /* border: 2px solid #1c8dcc !important;  */
}


.btn_yellow_outline {
  background-color: transparent !important;
  color: #A6598C !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  border-radius: 20px 0px 20px 0px;
  padding: 8px 20px !important;
  -webkit-box-shadow: 0 0 16px 5px rgb(0 0 0 / 20%);
  -moz-box-shadow: 0 0 16px 5px rgb(0 0 0 / 20%);
  box-shadow: 0 0 16px 5px rgb(0 0 0 / 20%);
  border: 1px solid #A6598C;
}

.btn_yellow_outline:hover {
  background-color: #A6598C !important;
  color: #000000 !important;
}

.carousel_home_theme .owl-stage-outer {
  padding: 70px 0px;
  /* margin: -20px; */
}

.card_carusel {
  position: relative;
}

.star_img_carousel {
  position: absolute;
  top: 0px;
  right: 20px;
  width: 35px !important;
  display: none !important;
  /* height: 11px !important; */
  /* z-index: 999; */
}

.owl-item.active.center .btn_yellow_outline {
  background-color: #A6598C !important;
  color: #000000 !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  border-radius: 20px 0px 20px 0px;
  padding: 8px 20px !important;
  -webkit-box-shadow: 0 0 16px 5px rgb(0 0 0 / 20%);
  -moz-box-shadow: 0 0 16px 5px rgb(0 0 0 / 20%);
  box-shadow: 0 0 16px 5px rgb(0 0 0 / 20%);
  border: 1px solid #A6598C;
}

.owl-item.active.center .btn_yellow_outline:hover {
  background-color: transparent !important;
  color: #A6598C !important;
}

.owl-item.active.center {
  transform: scaleY(1.2);
  z-index: 2;
}

.carousel_home_theme .owl-prev {
  position: absolute;
  left: -100px;
  top: 50%;
}

.carousel_home_theme .owl-next {
  position: absolute;
  right: -100px;
  top: 50%;
}

.home_sec_4 {
  position: relative;
  /* background: transparent radial-gradient(closest-side at 43% 43%, #011B33 0%, #000000 100%); */
  /* background-position: left -100px top 20px; */

}

.text_footer {
  color: #ffffff;
  font-size: 13px;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
}

.social_icon_div .fa {
  color: #ffffff;
  margin-left: 10px;
}

.social_icon_div a:hover .fa {
  color: #23b071;
}

#footer {
  border-top: 1px solid #ffffff;
}

@media only screen and (max-width:575px) {
  .carousel_home_theme .owl-stage-outer {
    padding: 10px 0px !important;
  }

  .owl-item.active.center {
    transform: scale(1) !important;
  }

  .carousel_home_theme .owl-prev {
    left: -20px !important;
  }

  .carousel_home_theme .owl-next {
    right: -20px !important;
  }

  .btn_yellow,
  .btn_yellow_outline {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .banner_txt_yel {
    font-size: 30px !important;
  }

  /* .carousel_home_theme .owl-stage-outer
  {
    padding: 35px 20px !important;
  } */
  .row_rev {
    flex-direction: column-reverse;
  }

  #footer .d-flex {
    display: block !important;
  }

  .social_icon_div a:first-child .fa {
    margin-left: 0px !important;
  }
}

@media only screen and (min-width:576px) and (max-width:767px) {
  .row_rev {
    flex-direction: column-reverse;
  }

  /* .carousel_home_theme .owl-stage-outer
  {
    padding: 35px 20px !important;
  } */
  .owl-item.active.center {
    transform: scale(1) !important;
  }

  .carousel_home_theme .owl-prev {
    left: -20px !important;
  }

  .carousel_home_theme .owl-next {
    right: -20px !important;
  }
}

@media only screen and (min-width:768px) and (max-width:991px) {
  .flex_drop_div p {
    padding-right: 2px !important;
  }

  .banner_txt_yel {
    font-size: 30px !important;
  }

  .btn_yellow,
  .btn_yellow_outline {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .coin_row .text_yellow {
    font-size: 14px !important;
  }

  .carousel_home_theme .owl-prev {
    left: -30px !important;
  }

  .carousel_home_theme .owl-next {
    right: -30px !important;
  }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
  .carousel_home_theme .owl-prev {
    left: -70px !important;
  }

  .carousel_home_theme .owl-next {
    right: -70px !important;
  }
}

/* .home_sec_4::after
{
  content: "";
  position: absolute;
  width: 90%;
  height: 100px;
  background: transparent radial-gradient(closest-side at 19% 43%, #011B33 0%, #000000 100%) 0% 0% no-repeat padding-box;
} */
.humb_icon {
  width: 35px;
  height: 3px;
  background-color: #030005;
  margin: 5px 0;
  border-radius: 2px;
}

.first_hamburger,
.second_hamburger,
.third_hamburger {
  position: relative;
}

#burger,
#burger .humb_icon {
  transition: 0.5s ease-in-out;
}

.first_hamburger::before,
.second_hamburger::before,
.third_hamburger::before {
  content: "";
  position: absolute;
  left: 0;
  background-color: #fff;
  width: 35px;
  height: 3px;
}

.second_hamburger {
  margin-left: 10px !important;
}

#basic-navbar-nav {
  justify-content: flex-end;
}

.navbar-toggler-icon {
  background-image: url("../src/images/navbar_icon.png") !important;
}

@media only screen and (max-width:991px) {
  .shadowstyle {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .shadowstyle.px-3 {
    padding-left: 15px !important;
    padding-right: 5px !important;
  }

  .navbar-nav {
    background-color: #060606;
    box-shadow: 0px 1px 12px #21a8f496;
    padding: 10px 20px 25px;
    position: absolute;
    width: 100%;
    z-index: 9;
    top: 50px;
    border-radius: 20px !important;
    flex-direction: column !important;
  }
}

#header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  background: transparent;
}

#header.colorChange {
  background: linear-gradient(216deg, #001522 50%, #001523 63%)
}

.navbar-expand-lg .navbar-nav {
  flex-direction: row;
  align-items: center;
}

.pt-main-top {
  padding-top: 110px;
}

.days {
  position: relative;
  max-width: max-content;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px !important;
}

.stakingpage .accordion .accordion-header p.days {
  font-size: 17px !important;

}

.days span {
  font-size: 12px !important;
  /* margin-top: -10px !important; */
  position: absolute;
  top: -3px !important;
  right: -10px !important;
}

.font_acc {
  font-size: 11px !important;
  font-weight: 600 !important;
}

.font_acc span:first-child {
  font-weight: 700 !important;
}

.font_acc:last-child {
  margin-bottom: 0px !important;
}

.accordion_row_height {

  /* toggle lines */
  /* min-height:320px !important; */
  /* toggle lines */

  min-height: 170px !important;

}

html,
body {
  scrollbar-color: #a65edf #1a1b1c;
  scrollbar-width: thin;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #187bb2;
}

*::-webkit-scrollbar-thumb {
  background-color: #a65edf;
}

.badge_pro {
  background-color: #21a8f4;
  color: #fff;
  width: 25px;
  height: 25px;
  position: absolute;
  right: 2px;
  top: 2px;
  border-radius: 0px 10px 0px 0px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.badge_pro .fa {
  font-size: 12px !important;
}

.imgblk {
  margin-right: 5px;
  filter: brightness(1) invert(1);
  max-width: 10px;
}

.imgwht {
  margin-right: 5px;

}

.headright_a {
  color: #21A8F4;
  font-size: 13px;
}

.headright_a:hover {
  color: #fff;
}

.text-right {
  text-align: right !important;
}

.white_text_sm {
  color: #fff;
  font-size: 13px !important;
  font-weight: 300 !important;
}

.yellow_text_big {
  color: #A6598C;
  font-size: 16px !important;
}

.progrs_yelw.progress {
  height: 8px;
  border-radius: 10px
}

.progrs_yelw.progress .progress-bar {
  background-color: #A6598C;

}

.row_progress_summary {
  position: relative;
  border-left: 1px solid #5E92DF;
  padding-left: 10px;
}

.row_progress_summary .col_left_prog::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #5E92DF;
  border-radius: 50%;
  left: -5px;
  top: 0px;
}

.row_progress_summary p {
  margin-top: -3px;
}

.row_without_circle_summary {
  position: relative;
  border-left: 1px solid #5E92DF;
  padding-left: 10px;

}

.row_progress_summary.row_progress_summary_last {
  border-left: none !important;
}

.custom-control-label span {
  color: #fff;
  font-size: 13px !important;
  font-weight: 300 !important;
}

.row_normal_summary {
  padding-left: 10px;

}

.circle-loader {
  margin-bottom: 20px;
  border: 5px solid rgba(0, 0, 0, 0.2);
  border-left-color: #1b9f62;
  animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: 7em;
  height: 7em;
}

.load-complete {
  -webkit-animation: none;
  animation: none;
  border-color: #1b9f62;
  transition: border 500ms ease-out;
}

/* .checkmark {
  display: none;
} */
.checkmark.draw:after {
  animation-duration: 800ms;
  animation-timing-function: ease;
  animation-name: checkmark;
  transform: scaleX(-1) rotate(135deg);
}

.checkmark:after {
  opacity: 1;
  height: 3.5em;
  width: 1.75em;
  transform-origin: left top;
  border-right: 5px solid #1b9f62;
  border-top: 5px solid #1b9f62;
  content: "";
  left: 1.25em;
  top: 3.5em;
  position: absolute;
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }

  20% {
    height: 0;
    width: 1.75em;
    opacity: 1;
  }

  40% {
    height: 3.5em;
    width: 1.75em;
    opacity: 1;
  }

  100% {
    height: 3.5em;
    width: 1.75em;
    opacity: 1;
  }
}

.custom-checkbox {
  align-items: center;
}

@media only screen and (max-width:767px) {
  .dot_double_por {
    display: none;
  }
}

@media only screen and (min-width:1200px) and (max-width:1349px) {
  .col-xl-4.col-xl-custom {
    width: 40% !important;
  }
}


/* END NEW CSS */



/*manoj css */
.innerpages .headsec {
  display: flex;
  justify-content: space-between;
}

.stakingpage .container_custom {
  max-width: 93% !important;
}

/* .stakingpage{
  background-color: #000;
} */

.innerpages .headsec .headtitle {
  color: #fff;
  letter-spacing: 1.75px;
  font-weight: 400;
}

.innerpages .headsec .headright {
  color: #21A8F4;
  font-size: 13px;
}

.headright:hover {
  color: #fff !important;
}

.card.shadowstyle {
  background: rgba(32, 182, 115, 0.2);
  box-shadow: 2px 2px 13px #23B071;
  /* background-color: #060606; */
  border-radius: 20px;
  /* box-shadow: 0px 1px 12px #21A8F496; */
  padding: 18px;
}

.card.shadowstyle .card-title {
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 300 !important;
}

.card-title-sm {
  color: #A6598C;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 300 !important;
}

.stakingpage .accordion {
  display: flex;
  gap: 10px;
  overflow: auto;

}

.stakingpage .accordion-item {
  min-width: 225px;
  max-width: 225px;
  background-color: unset !important;
  overflow: auto;

}

.stakingpage .accordion .accordion-button[aria-expanded="true"] .headerbg {
  /* background: transparent linear-gradient(228deg, #b8b047 28%, #6dae9b 100%) 0% 0% no-repeat padding-box !important; */
  background: #92c807 !important;
  padding: 5px;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  /* remove lines */
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  /* remove lines */

  /* add lines */

  /* border-bottom: 1px dashed #fff; */
  /* add lines */

}

.stakingpage .accordion-button:not(.collapsed) {
  color: transparent !important;
  border: 1px solid #b3af4b;
  border-radius: 5px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;

  /* remove lines */
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;

  /* remove lines */
}

.stakingpage .accordion-collapse {
  margin-top: -3px;
}

.stakingpage .accordion .accordion-button[aria-expanded="true"] .headerbg_black {
  width: 100%;
  padding: 16px;
  text-align: center;


}

.stakingpage .accordion-button:focus {
  border: none !important;
}

.stakingpage .accordion .accordion-button[aria-expanded="false"] .headerbg {
  background: #92c807 !important;
  padding: 2px;
  width: 100%;
  border-radius: 5px;

}

.stakingpage .accordion .accordion-button[aria-expanded="false"] .headerbg_black {
  background-color: #021523;
  border-radius: 5px;
  width: 100%;
  padding: 20px;
  text-align: center;
  height: 180px;
  min-height: 180px;
  max-height: 180px;
}

.stakingpage .accordion .accordion-button {
  padding: 0px;
}

.stakingpage .accordion-button::after {
  display: none;
}

.stakingpage .accordion-header {
  background: transparent;
  border: none !important;

}

.stack_token {
  margin-top: 10px;
  color: #92c807 !important;
}

.stakingpage .accordion-button[aria-expanded="false"] {
  color: #fff !important;
  background-color: #000;
  border-radius: 5px;
  border: none;
}

.stakingpage .accordion-button {
  display: flex;
  flex-direction: column;
  border-style: solid;
  background-color: transparent;
  border-width: 2px;
  /* border-image: linear-gradient(220deg, #A6598C, #21A8F4) 1;
  border-image-slice: 10; */
  border-radius: 5px;
}

.stakingpage .accordion-item .accordion-collapse .accordion-body {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  background: transparent linear-gradient(224deg, #b7b545 18%, #21A8F4 100%) 0% 0% no-repeat padding-box;

}

.stakingpage .accordion-body {
  text-align: center;

}

.stakingpage .accordion .accordion-header p {
  color: #fff;
  font-size: 14px !important;

}


/* .stakingpage .accordion-button[aria-expanded="true"]{
  color: #000 !important;
  background: transparent linear-gradient(212deg, #A6598C 0%, #21A8F4 100%) 0% 0% no-repeat padding-box !important;
} */
.stakingpage .accordion-button[aria-expanded="true"] p {
  color: #000 !important;
  font-weight: 700 !important;

}

.stakingpage .accordion-button:focus {
  box-shadow: none !important;

}

.stakingpage .stakingbtnsec {
  text-align: center;
  display: flex;
  justify-content: center;
}

.stakingpage .accordion-button .headerbg .days {
  font-weight: 800;

}

.stakingpage .stakingbtnsec button {
  width: 150px !important;
}

.stakingpage .input-group .form-control {
  background-color: #003633;
  border-radius: 6px;
  border: 1px solid #b5ff45;
  height: 48px;
  font-size: 13px !important;
  color: #d7d6d6;
}

.stakingpage .input-group .form-control::placeholder {
  font-size: 13px !important;
  color: #d7d6d6;

}

.stakingpage .input-group .input-group-append span {
  background-color: #003633;
  border-radius: 6px;
  border: 1px solid #b5ff45;
  height: 48px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  margin-top: 0px;

}

.stakingpage .input-group .input-group-append .input_btn {
  background-color: #92c807;
  border: none;
  border-radius: 5px;
  padding: 6px;
  width: 60px;
  font-size: 13px !important;
  border: 1px solid #92c807;
}

.stakingpage .input-group .input-group-append .input_btn:hover {
  background-color: transparent;
  color: #A6598C;
}

.stakingpage .descinput {
  color: #A6598C;
  font-weight: 300 !important;
  font-size: 14px !important;
}

.stakingpage .descline {
  color: #d7d6d6;
  font-size: 13px;
}

.stakingpage .input-group .input-group-append .dropdown button {
  background-color: #92c807;
  border-color: #92c807;
  border-radius: 5px;
  color: #000;
  height: 33.5px;
  color: #000;
  display: inline-flex;
  align-items: center;
  font-size: 13px;
  justify-content: space-between;
}

.form-control:focus {
  box-shadow: none;
  color: #fff;
}

.white_text_md {
  color: #fff;
  font-size: 16px !important;
  font-weight: 300 !important;
}


.rewardspage .form-control {
  background-color: #111111;
  border: none;
  height: 48px;
  color: #fff !important;
  font-size: 14px;
}

.rewardspage .firstrow .btn_yellow {
  width: 100%;
  height: 100%;
}

.innerpages .badgetheme {
  width: 100%;
  height: 48px;
  background: transparent linear-gradient(268deg, #4f4926 0%, #04374b 100%) 0% 0% no-repeat padding-box;

  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.innerpages .bange_long {
  width: 100%;
  height: 100%;
  background: #23B071 !important;
  padding: 10px 0px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.innerpages .bange_long span {
  word-wrap: break-word;
  white-space: break-spaces;
  line-height: 2;
  font-weight: 400;
}

.innerpages .badgetheme span {
  opacity: 1;
  font-weight: 400;
  letter-spacing: 0.9px;
}

.rewardspage .balancerow .bal_title {
  color: #ECECEC;
  text-align: left;
  letter-spacing: 0.9px;
  font-weight: 200;
  margin-bottom: 5px;
  font-size: 13px;
}

.rewardspage .balancerow .bal_value {
  color: #ECECEC;
  text-align: left;
  font-weight: 600;
  letter-spacing: 1.3px;
  margin-bottom: 5px;
  font-size: 15px;
}

.rewardspage .balancerow .bal_last {
  color: #ECECEC;
  text-align: left;
  letter-spacing: 0.9px;
  font-weight: 200;
  margin-bottom: 5px;
  font-size: 13px;

}

.rewardspage .rewardsbtnsec {
  text-align: center;


}

.rewardspage .rewardsbtnsec .btn_yellow {
  width: 150px !important;

}

.innerpages .table thead {
  /* background: transparent linear-gradient(268deg, #4f4926 0%, #04374b 100%) 0% 0% no-repeat padding-box; */
  background: #23B071;
  height: 48px;

}

.innerpages .table tbody .imgwhite {
  background-color: #fff;
  border-radius: 25px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;

}

.innerpages .table tbody .imgwhite .imgblk {
  margin-right: 0px;
}

.innerpages .table tbody .namelist {
  display: flex;
  gap: 5px;
  align-items: center;

}

.innerpages .table tbody tr,
.innerpages .table thead tr {
  color: #fff;

}

.innerpages .table tbody tr,
.innerpages .table tbody th {
  color: #fff;
  font-weight: 200;
}

.innerpages .table thead th {
  color: #fff;
  font-weight: 300;
  border: none;
  white-space: nowrap;
  vertical-align: middle;
  font-size: 14px;
}

.innerpages .tablesec {
  overflow: auto;
}

.innerpages .table tbody td,
.innerpages .table tbody th {
  border-bottom: 1px solid #7fa3a41c;
  white-space: nowrap;
}

.innerpages .table tbody {
  border-collapse: separate;
  border-spacing: 0 15px;
  border: none;
}

.innerpages .table tbody td,
.innerpages .table tbody th {
  padding: 0.7rem 0.5rem;
  font-size: 13px;
  letter-spacing: 1px;
  vertical-align: middle;
}

.innerpages .paginationsec .pagination {
  justify-content: center;
  gap: 5px;
}

.innerpages.rewardspage .secondcard .headsec .headtitle {

  font-size: 22px;
}

.innerpages .paginationsec .pagination .page-item .page-link {

  color: #000000;
  background-color: #4B4B4B;
  border-color: #4B4B4B;
  border-radius: 6px;
  min-width: 30px;
  max-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
}


.innerpages .paginationsec .pagination .page-item.active .page-link {

  color: #000000;
  background-color: #A6598C;
  border-color: #A6598C;
  border-radius: 6px;
  min-width: 30px;
  max-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
}

.innerpages .paginationsec .pagination .page-item:hover .page-link {

  color: #000000;
  background-color: #A6598C;
  border-color: #A6598C;
  border-radius: 6px;
}

.innerpages .paginationsec .pagination .page-item:last-child .page-link,
.innerpages .paginationsec .pagination .page-item:first-child .page-link {

  color: #000000;
  background-color: #A6598C;
  border-color: #A6598C;
  border-radius: 6px;
}

.innerpages .paginationsec .pagination .page-item.disabled .page-link {

  color: #000000;
  background-color: #857A38;
  border-color: #857A38;
  border-radius: 6px;
}

.innerpages .tablesec .table tbody td .valueplan {
  color: #23B071;
}


.innerpages .table thead tr th:first-child {

  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.innerpages .table thead tr th:last-child {

  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.calculatepage .midsec .mid_title {
  letter-spacing: 1px;
  color: #5BC15C;
  font-size: 14px;
}

.calculatepage .midsec .mid_value {
  letter-spacing: 2.25px;
  color: #ECECEC;
  font-size: 24px;
  font-weight: 500;
}

.calculatepage .card.shadowstyle,
.calculatepage .card-body {
  padding-top: 0px;
  padding-bottom: 0px;
}

.calculatepage .lastrightsec {
  padding-top: 24px;
  padding-bottom: 24px;
}

.calculatepage .leftsec {
  padding-top: 24px;
  padding-bottom: 24px;
  padding-right: 50px;
}

.calculatepage .midsec {
  display: flex;
  flex-direction: column;

  gap: 23px;
  padding: 55px;
  padding-top: 24px;
  padding-left: 5%;
  border-left: 1px solid#202020;
  border-right: 1px solid #202020;
}

.calculatepage .lastrightsec .title {
  color: #fff;
  letter-spacing: 1.75px;
  text-align: center;
  font-size: 25px;
  font-weight: 500;
}

.calculatepage .lastrightsec .desc {
  letter-spacing: 0.9px;
  color: #d7d6d6;
  font-size: 14px;
}

.calculatepage .lastrightsec .listdesc {
  padding-left: 30px;
  padding-right: 30px;
}

.calculatepage .lastrightsec .list_desc {
  color: #fff;
  letter-spacing: 0.9px;
  font-weight: 200;
  font-size: 12px;

}

.calculatepage .leftsec .val_cal {
  font-weight: 500;
  letter-spacing: 1.3px;
  font-size: 16px;
}

.calculatepage .leftsec .text_sliver {

  letter-spacing: 1px;
}

.calculatepage .leftsec .btn_yellow {
  width: 150px;
}


.calculatepage .lastrightsec .listdesc .listlines {
  position: relative;
}

.calculatepage .lastrightsec .listdesc .yellowcircle::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: #A6598C 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 3px #A6598C;
  border-radius: 50%;
  display: inline-block;
  margin-top: 5px;
  left: -30px;
}

.calculatepage .lastrightsec .listdesc .bluecircle::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: #21A8F4 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 3px #21A8F4;
  border-radius: 50%;
  display: inline-block;
  margin-top: 5px;
  left: -30px;
}

.calculatepage .lastrightsec .listdesc .lightcircle::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: #E8D257 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 3px #E8D257;
  border-radius: 50%;
  display: inline-block;
  margin-top: 5px;
  left: -30px;
}


.calculatepage .rightsection {
  height: 100%;
}

.claimmodal .title {
  font-size: 16px;
  color: #fff;
  letter-spacing: 1.8px;
}

.claimmodal .claimlists {
  display: flex;
  justify-content: space-between;

}

.claimmodal .claimlists p {
  color: #fff;
  font-size: 13px;
  font-weight: 200;
}

.claimmodal .claimmodal_btnsec {
  text-align: center;
}

.claimmodal .claimmodal_btnsec .btn_red,
.claimmodal .claimmodal_btnsec .btn_yellow {
  min-width: 100px;
}

@media only screen and (max-width:575px) {
  .stakingpage .stakingbtnsec button {
    width: 110px !important;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: column !important;
  }

  .rewardsbtnsec .btn_yellow {
    margin-left: auto !important;
    margin-right: auto !important;
    display: block !important;
    margin-bottom: 10px !important;
  }

  .rewardspage .balancerow .bal_title,
  .rewardspage .balancerow .bal_value,
  .rewardspage .balancerow .bal_last {
    text-align: center;
  }

  .lockedstaking.innerpages .headsec .headtitle {
    font-size: 18px;
  }

}

@media only screen and (max-width:767px) {

  .calculatepage .leftsec {
    padding-right: 0px;
  }

  .calculatepage .leftsec .btn_yellow {
    margin: auto;
  }

  .calculatepage .midsec {
    border: none;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
  }

  .calculatepage .lastrightsec .title {
    font-size: 24px;
  }

  .calculatepage .lastrightsec .listdesc {

    padding-right: 0px;
  }
}

@media only screen and (max-width:767px) {
  .innerpages .badgetheme span {
    white-space: break-spaces;
  }

}

@media only screen and (min-width:768px) and (max-width:1199px) {
  .calculatepage .leftsec .btn_yellow {
    margin: auto;
  }
}

@media only screen and (min-width:1200px) and (max-width:1350px) {
  .calculatepage .midsec {
    padding-left: 10%;
  }
}

.close-btn:hover {
  border: 1px solid #A6598C;
  color: #000;
  background-color: #A6598C !important;
}


.walletmodal .wallet_connect_div img {
  width: 35px;
}

.walletmodal .big_image_wallet {
  max-width: 120px;
}

@media only screen and (max-width:767px) {
  .walletmodal .big_image_wallet {
    max-width: 80px;
  }
}

@media only screen and (min-width:768px) and (max-width:1199px) {
  .landing_bg {
    min-height: unset;
  }
}

@media only screen and (max-width:991px) {
  #header .btn_yellow {
    margin-top: 10px;
  }
}

/* end manoj css */

.btn_yellow_a {
  text-decoration: none !important;
  display: inline-block !important;
}

button:disabled {
  opacity: 0.5 !important;
  cursor: not-allowed;
}

.showloadercard {
  position: absolute;
  width: 100%;
  left: 0px;
  top: 0px;
  background: #002518;
  width: 100%;
  z-index: 99;
  height: 100%;
  border-radius: 25px;

}

.loader_card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}


.loader_card img.spin {
  max-width: 150px;
  animation-name: spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.selct_neew_style,
.selct_neew_style:focus,
.selct_neew_style:hover {
  background-color: #003633;
  border: 1px solid #00b176;
  color: #fff !important;
  box-shadow: 0 0 0 0rem rgb(13 110 253 / 25%) !important;
}

.flex_bet_text {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width:575px) {

  .flex_bet_text,
  .flex_bet_text span {
    display: block !important;
  }
}

.content {

  display: inline;
}

.loading.blk_lkevl_stripe {
  display: block !important;
}

.stripe {
  min-height: 10px;
  background-color: #babbbc;
  display: inline-block;
}

.small-stripe {
  width: 40%;
}

.medium-stripe {
  width: 70%;
}

.long-stripe {
  width: 100%;
}

.loading {
  min-width: 100px;
  display: inline;
}

.loading .stripe {
  animation: hintloading 2s ease-in-out 0s infinite reverse;
  -webkit-animation: hintloading 2s ease-in-out 0s infinite reverse;
}

@keyframes hintloading {
  0% {
    opacity: 0.5;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.5;
  }
}

@-webkit-keyframes hintloading {
  0% {
    opacity: 0.5;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.5;
  }
}

.flex_drop_div {
  display: flex;
  align-items: center;
}

.flex_drop_div p {
  color: #fff;
  font-size: 13px;
  padding-right: 10px;
}

.flex_drop_div .dropdwn_trna button {
  margin-bottom: 15px !important;
}

/* .home_page .banner_row{
  background-image: url('../src/images/banner_bg.png');
  background-size: 100% 100%;
} */
.btn_yellow.calc_btn {

  background: #5E92DF;
  border-radius: 30px;
  border: none;
  color: #fff !important;
  font-weight: 500 !important;
  position: relative;
  height: 50px;
  padding-right: 60px !important;
  border: 1px solid transparent;
}

.btn_yellow.calc_btn span {
  position: absolute;
  top: 0px;
  right: 0px;
}

.btn_yellow.calc_btn span img {
  background-color: #fff;
  padding: 5px;
  border-radius: 50px;
}

.btn_yellow.calc_btn:hover {
  border: 1px solid #Fff;
}

.btn_yellow.wallet_btn {

  background: #A65EDF;
  border-radius: 30px;
  color: #fff !important;
  font-weight: 500 !important;
  border: 1px solid transparent;
  height: 50px;
}

.btn_yellow.wallet_btn:hover {
  background: transparent;
  border: 1px solid #A65EDF;
  border-bottom: 1px solid #A65EDF !important;
  color: #A65EDF !important;
}

.green_btn {
  background: #59A661;
  border-radius: 30px;
  border: 1px solid transparent;
  color: #fff !important;
  font-weight: 500 !important;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.green_btn:hover {
  border: 1px solid #59A661;
  color: #59A661 !important;
  background-color: transparent !important;

}

.text_blue {
  color: #0FC4E2;
}

.text_green {
  color: #5BC15C;
}

.btn_pink {
  background: #A6598C;
  border-radius: 30px;
  border: 1px solid transparent;
  color: #fff !important;
  font-weight: 500 !important;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.btn_pink:hover {
  border: 1px solid #A6598C;
  color: #A6598C !important;
  background-color: transparent !important;

}

.text_light_blue {
  color: #44FFDD;
}

.text_pink {
  color: #D139DE;
}

.connect_wallet_btn {
  background: #A65EDF;
  border-radius: 30px;
  color: #fff;
  border: 1px solid transparent;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500 !important;
  padding-right: 70px;
}

.connect_wallet_btn:hover {
  background: transparent;
  border: 1px solid #A65EDF;
  color: #A65EDF !important;
}

.connect_wallet_btn span {
  position: absolute;
  top: 0px;
  right: 0px;
}

.connect_wallet_btn span img {
  background-color: #fff;
  padding: 10px;
  border-radius: 50px;
}

.btn_blue {
  background: #5EC0DF;
  border-radius: 30px;
  color: #fff;
  border: 1px solid transparent;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500 !important;
  padding-right: 70px;
}

.btn_blue:hover {
  background: transparent;
  border: 1px solid #5EC0DF;
  color: #5EC0DF !important;
}

.btn_blue span {
  position: absolute;
  top: 0px;
  right: 0px;
}

.btn_blue span img {
  background-color: #fff;
  padding: 17px;
  border-radius: 50px;
}

.banner_sec_pos {
  position: absolute;
  bottom: -70px;
  text-align: center;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

.banner_sec_pos .banner_txt_yel {

  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  /* line-height: 48px; */
  letter-spacing: -0.02em;

  color: #FFFFFF;
  -webkit-text-stroke: 2px #000;
}

.banner_sec_pos .banner_txt_desc {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.02em;

  color: #FFFFFF;
  -webkit-text-stroke: 1px #000;
}

.banner_sec_pos .btn_secs {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn_earn {
  background: #A65EDF;
  border-radius: 30px;
  color: #fff;
  border: 1px solid transparent;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500 !important;
  padding-right: 70px;
}

.btn_earn:hover {
  background: transparent;
  border: 1px solid #A65EDF;
  color: #A65EDF !important;
}

.btn_earn span {
  position: absolute;
  top: 0px;
  right: 0px;
}

.btn_earn span img {
  background-color: #fff;
  padding: 14px;
  border-radius: 50px;
}

.home_sec_2 {
  position: relative;
}

.home_sec_2 .bg_shad {
  position: absolute;
  left: -50px;
}

.home_sec_3 {
  position: relative;
}

.home_sec_3 .bg_shad {
  position: absolute;
  right: -48px;
}

.home_sec_6 {
  position: relative;
}

.home_sec_6 .bg_shad {
  position: absolute;
  left: -55px;
  top: -15px;
}

@media only screen and (max-width:991px) {
  .bg_shad {
    display: none;
  }

}

@media only screen and (max-width:576px) {

  .landing_bg {

    background-image: url("../src/images/mobile_bg.png");
    background-size: 100% 300px;
    background-position: top;
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    min-height: 100vh;
    position: relative;
  }

  .banner_sec_pos .btn_secs {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
  }

  .banner_sec_pos .connect_wallet_btn {
    width: 200px;
  }

  .banner_sec_pos .btn_blue {
    width: 200px;
    margin-left: -12px;
  }

}

@media only screen and (min-width:576px) and (max-width:767px) {

  .landing_bg {

    background-size: 100% 450px;
    background-position: top;
    min-height: 100vh;

  }

  .banner_sec_pos .btn_secs {
    flex-direction: row;

  }

}

@media only screen and (min-width:768px) and (max-width:991px) {

  .landing_bg {

    background-size: 100% 450px;
    background-position: top;
    min-height: 100vh;
  }

  .banner_sec_pos .btn_secs {
    flex-direction: row;

  }

}

@media only screen and (min-width:992px) and (max-width:1199px) {

  .landing_bg {

    background-size: 100% 550px;
    background-position: top;
    min-height: 100vh;
  }

  .banner_sec_pos .btn_secs {
    flex-direction: row;

  }

  .home_sec_3 .bg_shad {
    position: absolute;
    right: -34px;
  }

}

.card_carusel.blue_card {
  background: #3e86b8;
}

.card_carusel.pink_card {
  background: #766599;
}

.card_carusel.green_card {
  background: #488971;
}

.card_carusel.purple_card {
  background: #af87cf;
}


#header .nav-link.btn_yellow.wallet_btn:hover {
  color: #A65EDF !important;
}

.blue_card .price_sec_div_rad {
  padding: 20px;
  border-radius: 30px 30px 30px 0px;
  background: #3398e0;
}

.pink_card .price_sec_div_rad {
  padding: 20px;
  border-radius: 30px 30px 30px 0px;
  background: #8a73b9;
}

.green_card .price_sec_div_rad {
  padding: 20px;
  border-radius: 30px 30px 30px 0px;
  background: #4bb891;
}

.purple_card .price_sec_div_rad {
  padding: 20px;
  border-radius: 30px 30px 30px 0px;
  background: #a65edf;
}

.card_ul_car li.mb-2 {
  margin-bottom: 0px !important;
}

.card_ul_car li i {
  display: none !important;
}

.navbar-toggler {
  box-shadow: 0 0 0 0rem #fff !important;
}


@media only screen and (min-width:1200px) {
  .landing_bg {
    background-size: cover !important;
  }
}

/* .MuiSlider-root span
    {
      color:#000 !important;
    } */

.form-select {
  background-image: url("../src/images/sort_down_dd.png");

}

.selct_neew_style option {
  background: #23B071 !important;
}